<template>
  <div>
  <v-row :key="forceReload" no-gutters style="border-bottom: 1px solid #CCCCCC80">
    <v-col
      cols="12"
      md="6"
      style="padding:10px"
    >
        <b>
          {{ $t('booking.name', locale) }}
        </b>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      {{ $t('booking.attendance.type', locale) }}
    </v-col>
  </v-row>
  <draggable
    v-model="items"
    :draggable="'.itemDraggable'"
    style="width:100%;"
    v-bind="dragOptions"
    @start="handleSortStart"
    @end="handleSortItems(items)"
  >
    <v-row 
      v-for="item in items" 
      :key="item.id" 
      v-bind:class="'itemDraggable'"
      style="padding: 0;margin: 0;cursor: pointer;"
    >
      <v-col
        cols="12"
        md="6"
        style="padding-left: 0;"
      >
        <v-icon
          small
          class="page__grab-icon"
          style="margin-right: 10px;"
        >
          mdi-cursor-move
        </v-icon>
        <a @click="onEdit(item)">{{ item.Name && getTranslation(item.Name, locale).length > 2 ? getTranslation(item.Name, locale) : item.ID }}</a>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        {{item.Type}} 
      </v-col>
    </v-row>
  </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import utils from '@/services/utils'
export default {
  name: 'List',
  components: {
    draggable,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onChangeSort: {
      type: Function,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    forceReload: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    items: [],
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  watch: {
    forceReload () {
      this.prepareData()
    },
    data () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      const aux = JSON.parse(JSON.stringify(this.data))

      this.items = aux ? aux.sort((a, b) => parseFloat(a.ORD) - parseFloat(b.ORD)) : null
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleSortItems () {
      if (this.items && this.items.length)
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].ORD = i
        }
      this.onChangeSort(this.items)
    },
    handleSortStart () {
    },
  }
}
</script>

